@tailwind base;
@tailwind components;
@tailwind utilities;

/* html,
body,
#__next {
  height: 100%;
} */

/*
 Center absolute positioned element on screen
*/
.center-absolute-screen {
  /* hardcoded size here, dynamic absolute size is super hard */
  --width: 12rem;
  --height: 10rem;
  position: fixed;
  width: var(--width);
  height: var(--height);
  left: calc(50% - var(--width) / 2);
  top: calc(50% - var(--height) / 2);
}
/*
 Basic CSS tooltip, inspired by https://www.w3schools.com/howto/howto_css_tooltip.asp
*/
.tooltip-target {
  position: relative;
  display: flex;
  flex-direction: column;
}
.tooltip-target .tooltip {
  align-self: center;
  @apply invisible px-2 py-1 bg-gray-700 text-white text-center rounded-md;
  min-width: 20rem;

  /* Position the tooltip */
  @apply absolute z-10;
  bottom: calc(100% + 4px);

  /* Fade in tooltip */
  @apply opacity-0 transition-opacity;
}

/* Tooltip arrow */
.tooltip-target .tooltip::after {
  @apply absolute top-full left-1/2 border-4;
  content: '';
  border-color: rgba(55, 65, 81) transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-target:hover .tooltip {
  @apply visible opacity-100;
}

/*
 -------------------
 FONTS
 built using https://google-webfonts-helper.herokuapp.com/fonts/roboto?subsets=latin
 -------------------
*/

@layer base {
  /* roboto-100 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local(''), url('/fonts/roboto/roboto-v20-latin-100.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/roboto/roboto-v20-latin-100.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-100italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local(''), url('/fonts/roboto/roboto-v20-latin-100italic.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/roboto/roboto-v20-latin-100italic.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-300 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''), url('/fonts/roboto/roboto-v20-latin-300.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/roboto/roboto-v20-latin-300.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-regular - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''), url('/fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/roboto/roboto-v20-latin-regular.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-300italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local(''), url('/fonts/roboto/roboto-v20-latin-300italic.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/roboto/roboto-v20-latin-300italic.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local(''), url('/fonts/roboto/roboto-v20-latin-italic.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/roboto/roboto-v20-latin-italic.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-500 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''), url('/fonts/roboto/roboto-v20-latin-500.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/roboto/roboto-v20-latin-500.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-500italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local(''), url('/fonts/roboto/roboto-v20-latin-500italic.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/roboto/roboto-v20-latin-500italic.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-700 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''), url('/fonts/roboto/roboto-v20-latin-700.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/roboto/roboto-v20-latin-700.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-700italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local(''), url('/fonts/roboto/roboto-v20-latin-700italic.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/roboto/roboto-v20-latin-700italic.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-900italic - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local(''), url('/fonts/roboto/roboto-v20-latin-900italic.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/roboto/roboto-v20-latin-900italic.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* roboto-900 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local(''), url('/fonts/roboto/roboto-v20-latin-900.woff2') format('woff2'),
      /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/roboto/roboto-v20-latin-900.woff')
        format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
}
